import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"


import Tab from "../../components/history/tabs"
import Award from "../../components/award"

const AwardPage = ({ location }) => {
  const intl = useIntl()
  const language = intl.locale



  return (
    <Layout location={location}>
      <SEO
        location={location}
        lang={language}
        title={language !== "ja" ? "Award" : "受賞歴"}
        keywords={[`award`, `受賞歴`, `放射線像`, `Autoradiograph`]}
        description={language !== 'ja' ? `Receiving awards history` : `これまでの受賞歴です。`}
      />
      <Tab location={location}>
        <Award />
      </Tab>
    </Layout>
  )
}

export default AwardPage
